<template>
<section class="flex flex-col items-center h-screen mx-auto text-center bg-gray-900">
   <svg role="presentation" class="block w-24 m-auto lg:mx-0" xmlns="http://www.w3.org/2000/svg" width="40" viewBox="0 0 44.078 67.703"> <g id="Group_479" data-name="Group 479" transform="translate(1.002 1.376)"> <path id="Path_433" data-name="Path 433" d="M247.424,4535.453s-.477-5.715,6.53-13.748c6.2-7.254,11.607-20.267,8.087-30.256a43.253,43.253,0,0,0-17.864-22.24s-11.417,18.553,12.655,37.2c0,0,5.994-11.227-9.324-25.212" transform="translate(-241.472 -4469.209)" fill="none" stroke="#fff" stroke-width="2"></path> <path id="Path_434" data-name="Path 434" d="M266.24,4482.96s7.422-7.041,12.083,2l10.56,2.377-11.988,1.332a7.055,7.055,0,0,1-4.734,1.441,2.737,2.737,0,0,1-2.3-3.074c-.216-1.559,2.988-3.507,4.2-.606" transform="translate(-246.027 -4471.258)" fill="none" stroke="#fff" stroke-width="2"></path> <path id="Path_435" data-name="Path 435" d="M274.824,4495.392s3.074,11.4-.434,18.243-12.775,7.967-12.775,7.967" transform="translate(-245.177 -4474.025)" fill="none" stroke="#fff" stroke-width="2"></path> </g> </svg>
   <div class="flex flex-col pb-3 mt-5 text-xs text-white bottom-2">
   <p>Copyright © {{ new Date().getFullYear() }} Lifeboost Coffee.</p>
   <p>All rights reserved.</p>
   </div>
</section>
</template>

<script setup>
useSeoMeta({
  layout:() => "default",
  title: () => "Save on Lifeboostcoffee.com",
  description: () => "LifeboostCoffee.com Sale on Steroids!",
})
</script>